import api from "@/base/utils/request";

// 获取上级组织列表
export const PidList = data => {
  return api({
    url: "/admin/cyc/multi_level/parents",
    method: "post",
    data
  });
};

// 获取上级组织列表
export const ParentList = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};
