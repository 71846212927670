<template>
  <div class="multi-level-member-group">
    <draggable v-if="myList.length > 0" class="group-list" v-model="myList">
      <el-card v-for="(g, gI) in myList" :key="g.label" shadow="never" :body-style="{
        padding: '16px 0 4px 12px'
      }" class="group-card">
        <div slot="header" class="flex-between">
          <div class="header flex-align-center">
            <i class="el-icon-sort cursor-pointer" />
            <label class="label"><span style="color: red;">*</span>
              <input placeholder="请输入分类名称" @input="g.isClassify = false" v-model="g.name" />
            </label>
            <div class="prompt" v-show="g.isClassify">请填写成员展示的分类名称</div>
          </div>
          <i class="el-icon el-icon-delete cursor-pointer" @click="deleteConfirm(g, gI)" />
        </div>
        <div class="flex">
          <label class="el-form-item__label" style="margin-right: 8px;"><span style="color: red;">* </span>添加成员：</label>
          <draggable class="member-list flex" v-model="g.hosts" handle=".el-icon-rank" filter=".forbid" :move="onMove">
            <div class="member flex-align-center" v-for="(m, mI) in g.hosts" :key="m.randomId">
              <i class="el-icon-rank cursor-pointer" />
              <el-tooltip class="item flex-1" effect="dark" :content="m.description || m.title_name || m.position_name"
                placement="bottom">
                <div class="flex-align-center">
                  <list-image :src="fixImageUrl(m.avatar || DefaultAvatar, 150)" fit="cover" border-radius="50%"
                    :width="42" />
                  <div class="member-info flex-col flex-between">
                    <span class="name">{{ m.nickname || m.name }}<span v-if="m.type">（游客）</span></span>
                    <!-- 游客简介，会员职务，校友信息 -->
                    <span v-if="m.type" class="position">{{ m.description || '' }}</span>
                    <span v-else-if="tenantVersion !== 'school'" class="position">{{ m.title_name || m.position_name ||
                      ''
                    }}</span>
                    <span v-else class="position">{{ m.class_info || '' }}</span>
                  </div>
                </div>
              </el-tooltip>
              <el-divider direction="vertical" />
              <i class="el-icon el-icon-edit cursor-pointer" @click="editMember(gI, mI)" />
              <i class="el-icon el-icon-delete cursor-pointer" @click="deleteMemberConfirm(gI, m, mI)" />
            </div>
            <add-button class="forbid" :width="257" :height="66" @click="addMember(gI)">+添加</add-button>
            <div class="prompt" v-show="g.isMember">请添加成员展示的成员</div>
          </draggable>
        </div>
      </el-card>
    </draggable>

    <!-- 添加分类/分组 -->
    <el-button v-if="myList.length === 0" type="primary" @click="addGroup">添加</el-button>
    <add-button v-else style="margin-top: 12px;" @click="addGroup">+ 添加</add-button>

    <!-- 选择会员弹窗 -->
    <SelectMember v-model="openSelectMember" :Members="emptyHosts" :disable-members="hosts" :selectType="0"
      selected-type="check" :currentIndex="currentIndex" @changeSelect="handleSelect" />

    <!-- 编辑、添加成员（会员、游客）弹窗 -->
    <el-dialog class="dialog-vertical" @close="openMemberType = false" :visible="openMemberType"
      :title="isAdd ? '添加' : '编辑'" v-if="openMemberType">
      <el-form :model="form" class="medium-form" label-width="100px" size="medium">
        <el-form-item label="用户类型：">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">会员</el-radio>
            <el-radio :label="1">游客</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type === 0" label="选择会员：" required>
          <el-button type="plain" @click="handleSelectMember()">请选择</el-button>
        </el-form-item>
        <template v-else>
          <el-form-item label="头像：">
            <div class="avatar">
              <single-media-wall v-model="form.avatar" addText="" :width="85" :height="85" borderRadius="50%"
                useDefaultAvatar />
            </div>
          </el-form-item>
          <el-form-item label="用户名称：" required>
            <el-input v-model="form.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="简介：" required>
            <el-input v-model="form.description" placeholder="请输入" />
          </el-form-item>
        </template>
      </el-form>
      <template v-if="form.type === 1" #footer>
        <el-button type="" size="medium" @click="openMemberType = false">取消</el-button>
        <el-button type="primary" size="medium" @click="finish">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ListImage from '../../../base/components/List/ListImage'
import AddButton from '../../../base/components/Base/AddButton'
import SelectMember from "@/modules/common/components/MemberSelector";
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue';

import { randomString } from '../../../base/utils/tool'

export default {
  name: 'MultiLevelMemberGroup',
  components: {
    AddButton,
    ListImage,
    draggable,
    SelectMember,
    SingleMediaWall
  },
  props: {
    list: {
      type: Array
    },
  },
  data() {
    return {
      myList: [],
      currentIndex: -1,
      openSelectMember: false,
      isAdd: true,
      openMemberType: false,
      form: {
        // 0: 会员，1：游客
        type: 0,
        avatar: '',
        name: '',
        description: ''
      },
      hosts: [],
      DefaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png", // 默认头像
      emptyHosts: []
    }
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant?.version;
    },
  },
  watch: {
    'list.length'() {
      this.myList = JSON.parse(JSON.stringify(this.list.map((el, elI) => ({
        ...el,
        randomId: randomString(8),
        hosts: this.list[elI].hosts.map(h => ({
          ...h,
          randomId: randomString(8)
        }))
      }))))
    }
  },
  methods: {
    // 验证是否有添加负责人
    passHosts() {
      this.myList = this.myList.map(item => {
        console.log(item);
        if (item.name) {
          item.isClassify = false
        } else {
          item.isClassify = true
        }
        if (item.hosts.length == 0) {
          item.isMember = true
        } else {
          item.isMember = false
        }

        return item
      })


    },
    finish() {
      const e = this.form
      if (this.isAdd) {
        this.myList[this.currentIndex].hosts.push({ ...e, randomId: randomString(8) })
      } else {
        const cur = this.myList[this.currentIndex].hosts[this.subIndex]
        this.myList[this.currentIndex].hosts.splice(this.subIndex, 1, {
          ...cur,
          name: e.name,
          description: e.description,
          avatar: e.avatar,
          type: 1,
        })
      }

      this.openMemberType = false
    },
    // 点击选择会员
    handleSelectMember() {
      this.emptyHosts = []
      this.hosts = JSON.parse(JSON.stringify(this.myList[this.currentIndex].hosts))
      this.openSelectMember = true;
    },
    // 获取选择会员
    handleSelect(e) {
      e = e[0]
      if (this.isAdd) {
        this.myList[this.currentIndex].hosts.push({
          nickname: e.nickname,
          position_name: e.position_name,
          description: '',
          type: 0,
          user_id: e.user_id,
          avatar: e.avatar,
          randomId: randomString(8)
        })
      }
      else {
        const cur = this.myList[this.currentIndex].hosts[this.subIndex]
        this.myList[this.currentIndex].hosts[this.subIndex] = {
          ...cur,
          nickname: e.nickname,
          position_name: e.position_name,
          type: 0,
          description: '',
          user_id: e.user_id,
          avatar: e.avatar,
        }
      }
      this.openMemberType = false
    },
    editMember(i, ii) {
      this.subIndex = ii
      this.form = { ...this.form, ...this.myList[i].hosts[ii] }
      this.currentIndex = i
      this.openMemberType = true
      this.isAdd = false
    },
    addMember(index) {
      this.currentIndex = index
      this.form.type = 0
      this.form.name = ''
      this.form.description = ''
      this.form.avatar = ''
      this.isAdd = true
      this.openMemberType = true
      this.myList = this.myList.map((item, i) => {
        if (i == index) {
          item.isMember = false
        }
        return item
      })
    },
    addGroup() {
      this.myList.push({
        name: '',
        hosts: [],
        randomId: randomString(8)
      })
    },
    deleteMemberConfirm(index, it, i) {
      const greet = `成员 ${it.name || it.nickname} `
      this.$confirm(`确定要删除${(it.name || it.nickname) ? greet : '当前成员'}吗？`, '提示', {
        type: 'info'
      }).then(() => {
        this.myList[index].hosts.splice(i, 1)
      }).catch(() => { })
    },
    deleteConfirm(item, index) {
      if (!item.name && item.list?.length === 0) {
        this.myList.splice(index, 1)
        return
      }
      this.$confirm(`确定要删除分类 ${item.name || '当前分类'} 及成员数据吗？`, '提示', {
        type: 'info'
      }).then(() => {
        this.myList.splice(index, 1)
      }).catch(() => { })
    },
    onMove(e) {
      // return false 表示不可拖拽/停靠
      return !!e.relatedContext.element;
    },
  }
}
</script>

<style lang="scss" scoped>
.multi-level-member-group {}

.group-list {
  background-color: #F5F7FA;
  padding: 16px;
  max-width: 930px;
}

.group-card+.group-card {
  margin-top: 12px;
}

.el-card {
  border: none;
}

.header {
  position: relative;
  .el-icon-sort {
    margin-right: 12px;
  }

  input {
    border: none;
    outline: none;
    margin-left: 5px;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
  }
  .prompt{
    position: absolute;
    bottom: -10px;
    color: #FF4949;
    font-size: 12px;
    line-height: 1;
  }
}

.member-list {
  flex-wrap: wrap;
  position: relative;
  .prompt{
    position: absolute;
    bottom: -2px;
    left: -78px;
    color: #FF4949;
    font-size: 12px;
    line-height: 1;
  }
  .member {
    width: 257px;
    height: 66px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
    padding: 12px;
    /*border: .5px solid rgba(0,0,0,0.06);*/
  }

  .add-button,
  .member {
    margin-right: 8px;
    margin-bottom: 12px;
  }

  .list-image {
    margin-left: 12px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .el-icon+.el-icon {
    margin-left: 12px;
  }

  .el-divider--vertical {
    margin: 0 16px;
  }

  .name,
  .position {
    width: 100%;
    @include nowrap();
  }

  .name {
    color: rgba(0, 0, 0, 1);
    font-size: 13px;
    line-height: 1.54;
  }

  .position {
    color: rgba(128, 128, 128, 1);
    font-size: 12px;
    line-height: 1.5;
  }

  .flex-1 {
    flex: 1;
    min-width: 0;
  }

  .member-info {
    height: 42px;
    overflow: hidden;
    align-items: flex-start;
    padding: 1px 0;
  }
}
</style>
