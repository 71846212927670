<template>
  <div class="brand-content" v-loading="formLoading">
    <el-form size="medium" :model="brandForm" :rules="rules" label-position="right" label-width="110px"
      class="small-form" ref="brandForm" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">基础信息</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item :label="tenantVersion === 'school' ? '校友会名称：' : '组织名称：'" prop="name">
            <el-input type="text" v-model="brandForm.name"
              :placeholder="tenantVersion === 'school' ? '请输入校友名称' : '请输入组织名称'"></el-input>
          </el-form-item>
          <el-form-item :label="tenantVersion === 'school' ? '简介：' : '组织简介：'" prop="description">
            <el-input type="textarea" :rows="10" :maxlength="3000" show-word-limit v-model="brandForm.description"
              placeholder="请输入组织简介" />
          </el-form-item>
          <!--          <el-form-item label="负责人：" prop="hosts">-->
          <!--            <host-card v-model="brandForm.hosts" />-->
          <!--          </el-form-item>-->
         
          <el-form-item label="成员展示：" prop="categories">
            <multi-level-member-group  ref="myCategories" :list="brandForm.categories" />
            <p class="info">
              <i class="el-icon-warning" />点击拖动调整分类排序
            </p>
          </el-form-item>
          <el-form-item :label="tenantVersion === 'school' ? '所属校友会：' : '所属组织架构：'" prop="organization_id">
            <el-cascader style="width: 295px" v-if="positionOptions" v-model="brandForm.organization_id"
              :options="positionOptions" :props="props" @change="handleExportCatalogChange" ref="CascaderRef"
              filterable>
            </el-cascader>
          </el-form-item>
        </div>
      </div>
      <!-- <el-form-item label="上级组织：" prop="pid">
        <el-select v-model="brandForm.pid" placeholder="请选择上级组织" style="width: 295px">
          <el-option v-for="item in pid_list" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="运营收藏数：" prop="virtual_collect">
        <el-input type="number" v-model.number="brandForm.virtual_collect" placeholder="请输入运营收藏数"></el-input>
      </el-form-item> -->
      <div class="modular">
        <p class="modular-title">运营图</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="运营图片：" prop="images">
            <el-form class="input-imgs small-form" size="medium" :model="brandForm.images" :rules="imageRules"
              ref="images" @submit.native.prevent>
              <el-form-item class="input-item media-input-item" prop="page_cover">
                <image-wall v-model="brandForm.images.page_cover" :width="194" :height="135">
                  <p slot="info" class="info-title">列表封面</p>
                  <p slot="info" class="img-tips">具体尺寸见 “页面装修” 组件选中的模板尺寸</p>
                </image-wall>
                <el-input v-show="false" v-model="brandForm.images.page_cover"></el-input>
              </el-form-item>
              <el-form-item class="input-item media-input-item" prop="detail_cover">
                <image-wall ratio="750:524" v-model="brandForm.images.detail_cover" :width="194" :height="135">
                  <p slot="info" class="info-title">详情头部</p>
                  <p slot="info" class="img-tips">建议上传尺寸比例为750*524</p>
                </image-wall>
                <el-input v-show="false" v-model="brandForm.images.detail_cover"></el-input>
              </el-form-item>
            </el-form>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">页面分享</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="封面图：" prop="site_share">
            <div class="input-item">
              <image-wall ratio="500:400" v-model="brandForm.site_share" :width="194" :height="135">
                <p slot="info" class="img-tips" style="margin-top: 8px">
                  <span class="el-icon-info"></span>建议上传尺寸比例为1500*1200,不上传图片时，默认使用系统自带图片
                </p>
              </image-wall>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" size="medium" @click="formSubmit('brandForm')" :loading="saveLoading">保存 </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import hostCard from "../components/HostCard.vue";
import { brandDetail, brandSave } from "../api/add-brand";
import { ParentList } from "../api/parent-list";
import ImageWall from "../../common/components/SingleMediaWall.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import MultiLevelMemberGroup from '../components/MultiLevelMemberGroup'
export default {
  components: { MultiLevelMemberGroup, hostCard, ImageWall, FixedActionBar },
  data() {
    return {
      //成员分类验证
  
      brandForm: {
        id: Number(this.$route.params.id),
        name: "",
        description: "",
        // hosts: [],
        categories: [],
        organization_id: "",
        virtual_collect: 0,
        images: {
          detail_cover: "",
          page_cover: "",
        },
        site_share: "",
      },
      rules: {
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        description: [{ required: true, message: "请输入组织简介", trigger: "blur" }],
        categories: [{ required: true, validator: this.passHosts, trigger: "change" }],
        organization_id: [
          {
            required: true,
            message: "请选择所属组织架构组织",
            trigger: "change",
          },
        ],
        virtual_collect: [
          { required: true, message: "请输入运营收藏数", trigger: "blur" },
          { type: "integer", min: 0, message: "请输入正整数", trigger: "blur" },
        ],
        images: [
          {
            required: true,
            validator: this.passImages,
            trigger: "change",
          },
        ],
      },
      imageRules: {
        detail_cover: [{ required: true, message: "请上传详情头部", trigger: "change" }],
        page_cover: [{ required: true, message: "请上传首页封面", trigger: "change" }],
      },
      props: {
        checkStrictly: true,
        expandTrigger: "hover",
        label: "name",
        value: "id",
        emitPath: false,
      },
      positionOptions: [],
      pid_list: [],
      formLoading: false,
      openSelectImg: false,
      imgSelectType: "", //当前选中图片属性
      saveLoading: false,
    };
  },
  methods: {
    //获取所属架构列表
    getPositionOptions() {
      ParentList({ is_show_none: 0, is_all: 1 }).then((res) => {
        const { data } = res;
        this.positionOptions = data;
      });
    },
    // 验证是否有添加负责人
    passHosts(rule, value, callback) {
      const ref = this.$refs.myCategories

      if (!ref) {
        callback(new Error("请添加成员展示"));
      } else if (ref.myList.length === 0) {
        callback(new Error("请添加成员展示"));
      } else {
        if (ref.myList.filter(el => el.name === '').length > 0) {
          //调用子组件的方法进行表单验证
          ref.passHosts()
          callback(new Error(" "));
        } else if (ref.myList.filter(el => el.hosts.length === 0).length > 0) {
               //调用子组件的方法进行表单验证
          ref.passHosts()
          callback(new Error(" "));
        } else callback();
      }
    },
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    // 验证运营图片上传
    passImages(rule, value, callback) {
      if (value) {
        this.$refs.images.validate((valid) => {
          if (valid) {
            callback();
          } else {
            this.saveLoading = false;
          }
        });
      } else {
        callback();
      }
    },
    // 获取品牌详情
    getBrandDetail() {
      this.formLoading = true;
      brandDetail({ id: this.brandForm.id })
        .then((res) => {
          const { data } = res;
          this.brandForm = data;
          this.formLoading = false;
          if (data?.categories?.length === 0) {
            this.$nextTick(() => {
              this.$refs.myCategories.addGroup()
            })
          }
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const { hosts, ...data } = { ...this.brandForm };
          this.saveLoading = true;
          // data.hosts = JSON.stringify(data.hosts);
          data.categories = JSON.stringify(this.$refs.myCategories.myList.map((g, gi) => {
            return {
              name: g.name,
              hosts: g.hosts.map(h => {
                const { randomId: r, ...otherH } = h
                return {
                  ...otherH,
                  user_id: otherH.user_id || 0,
                  nickname: otherH.nickname || '',
                  name: otherH.name || '',
                  position_name: otherH.position_name || '',
                }
              })
            }
          }));
          console.log(JSON.parse(data.categories));
          data.images = JSON.stringify(data.images);
          brandSave(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.$router.push({ name: "MultiLevelList" });
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center", behavior: "smooth" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: "MultiLevelList" });
    },
  },
  created() {
    // 获取上级组织列表
    this.getPositionOptions();
    if (this.brandForm.id) {
      this.getBrandDetail();
    }
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-content {
  min-height: 520px;

  .add-host:hover {
    border-color: #409eff;
  }

  .input-imgs {
    display: flex;
  }

  .footer-btn {
    @include fixed-card-bottom();
    padding-left: 250px;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;

      .plus-icon {
        font-size: 20px;

        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .info-title {
      font-size: 14px;
      line-height: 30px;
    }

    .img-tips {
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
    }

    .upload-btn:hover {
      border-color: #409eff;
    }
  }
}
</style>
