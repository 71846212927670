import api from "@/base/utils/request";

// 获取品牌详情
export const brandDetail = data => {
  return api({
    url: "/admin/cyc/multi_level/detail",
    method: "post",
    data
  });
};

// 保存品牌
export const brandSave = data => {
  return api({
    url: "/admin/cyc/multi_level/save",
    method: "post",
    data
  });
};
