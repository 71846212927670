<template>
  <!-- TODO: 组件已过时！！！使用 MultiLevelMemberGroup 代替了 -->
  <div>
    <el-card shadow="never" :body-style="{ padding: 0 }" v-for="(host, index) in Hosts" :key="index">
      <div slot="header" class="clearfix">
        <el-button class="card-close el-icon-close" type="text" @click="delHost(index)"></el-button>
      </div>
      <el-form size="medium" :model="host" label-position="right" label-width="80px" class="host-form">
        <el-form-item label="头像：">
          <el-avatar :src="host.avatar ? host.avatar : defaultAvatar" :size="60" />
        </el-form-item>
        <el-form-item label="名称：">
          <el-input
            type="text"
            disabled
            v-model="host.nickname"
            style="width: 200px; margin-right: 20px"
            placeholder="请选择会员"
          ></el-input>
          <el-button @click="handleSelectMember(index)">选择用户</el-button>
        </el-form-item>
        <el-form-item :label="tenantVersion === 'school' ? '届别：' : '会内职务：'">
          <el-input
            v-if="tenantVersion !== 'school'"
            type="text"
            disabled
            v-model="host.title_name"
            placeholder="--"
          ></el-input>
          <el-input
            v-if="tenantVersion === 'school'"
            type="text"
            disabled
            v-model="host.class_info"
            placeholder="--"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-button type="default" class="add-host opacity-btn" @click="addHost">+ 添加负责人</el-button>
    <SelectMember
      v-model="openSelectMember"
      :Members="Hosts"
      :selectType="selectType"
      :currentIndex="currentIndex"
      @changeSelect="handleSelect"
    />
  </div>
</template>

<script>
import SelectMember from "@/modules/common/components/MemberSelector";
// TODO: 从选择用胡这个弹窗反蝴蝶信息拿学校字段，然后判断是否校友版在显示出来!!!!!!!!!

export default {
  model: {
    prop: "hostList",
    event: "uploadHost",
  },
  props: {
    hostList: {
      type: Array,
    },
  },
  data() {
    return {
      memberOptions: [],
      defaultAvatar: require("@/modules/admin/assets/images/avatar.png"),
      selectType: 1,
      currentIndex: 0,
      openSelectMember: false,
    };
  },
  methods: {
    // 添加负责人
    addHost() {
      this.selectType = 1;
      this.openSelectMember = true;
    },
    //删除负责人
    delHost(i) {
      this.Hosts.splice(i, 1);
    },
    // 点击选择会员
    handleSelectMember(i) {
      this.selectType = 0;
      this.currentIndex = i;
      this.openSelectMember = true;
    },
    // 获取选择会员
    handleSelect(e) {
      this.Hosts = e;
    },
  },
  created() {},
  computed: {
    Hosts: {
      get() {
        return this.hostList;
      },
      set(val) {
        this.$emit("uploadHost", val);
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  components: { SelectMember },
};
</script>

<style lang="scss" scoped>
.el-card {
  padding: 20px;
  border: 1px dashed #d9d9d9;
  width: 500px;
  margin-bottom: 10px;

  .card-close {
    float: right;
    padding: 0;
    font-size: 20px;
    color: #606266;
  }

  .host-form {
    ::v-deep.el-form-item {
      margin-bottom: 20px;
    }
  }

  ::v-deep.el-card__header {
    padding: 0px;
    height: 20px;
    border: none;
  }
}

.add-host {
  width: 500px;
  border: 1px dashed #d9d9d9;
  padding: 10px 0;
}

.add-host:hover {
  border: 1px dashed #3576ff;
}
</style>
